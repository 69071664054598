import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const userStatus = localStorage.getItem('user_status');

  if (!isAuthenticated || userStatus === '0') {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;