import React from 'react';
import { createHashRouter, Navigate, Outlet } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import Error500 from '../Modules/Error500';
import Error404 from '../Modules/Error404';
import Error401 from '../Modules/Error401';
import Dashboard from '../Modules/Dashboard';
import Administration from '../Modules/UserSetting/Administration';
import SendMessage from '../Modules/UserSetting/SendMessage';
import Massages from '../Modules/UserSetting/Massages';
import InboxMassage from '../Modules/UserSetting/inboxMessage';
import ReplyMessage from '../Modules/UserSetting/replyMassage';
import InboxSentMessage from '../Modules/UserSetting/inboxSentMessage';

const ProjectRouter = createHashRouter([
    {
        path: "/",
        element: <PrivateRoute><Outlet /></PrivateRoute>,
        children: [
            {
                path: "/",
                element: <Navigate to="dashboard" />, // Redirect to dashboard by default
            },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "administration",
                element: <Administration />,
            },
            {
                path: "messages",
                element: <Massages />,
            },
            {
                path: "send-message",
                element: <SendMessage />,
            },
            {
                path: "/message-reply/:id/:senderId",
                element: <ReplyMessage />,
            },
            {
                path: "/recivedbox/:id",
                element: <InboxMassage />,
            },
            {
                path: "sentbox/:id",
                element: <InboxSentMessage />,
            },
            {   // error page
                path: "error-500",
                element: <Error500 />,
            },
            {
                path: "*",
                element: <Error404 />,
            },
            {
                path: "error-401",
                element: <Error401 />,
            },
        ],
    },
]);

export default ProjectRouter;
