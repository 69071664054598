import React from 'react';
import { createHashRouter, Navigate, Outlet } from 'react-router-dom';
import Auth from '../Modules/Auth';
import Login from '../Layouts/Login';
import Error404 from '../Modules/Error404';

const PublicRouter = createHashRouter([
    {
        path: "/",
        element: <Outlet />,
        children: [
            {
                path: "/",
                element: <Navigate to="auth" />, 
            },
            {
                path: "auth",
                element: <Auth />,
            },
            {  
                path: "*",
                element: <Error404 />,
            },
        ],
    },
]);

export default PublicRouter;
