import React, { useEffect, useState } from 'react';
import { AxiosAuthInstance } from '../../AxiosInterceptors';
import Constant from '../../Constant';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const [unreadCount, setUnreadCount] = useState(0);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchUserRole = async () => {
            try {
                const response = await AxiosAuthInstance.get('/current-user');
                setUserRole(response.data.user.role);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUserRole();

        const fetchMessages = async () => {
            try {
                const response = await AxiosAuthInstance.get(`${Constant.BASE_URL}/messages`);
                calculateUnreadCount(response.data.received);
            } catch (error) {
                console.error('Error fetching messages:', error);
                console.error('Response data:', error.response ? error.response.data : 'No response data');
                setError('Failed to fetch messages.');
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();

    }, []);

    const fetchUnreadCount = async () => {
        try {
            const response = await AxiosAuthInstance.get(`${Constant.BASE_URL}/messages`);
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Error fetching unread count:', error);
            console.error('Response data:', error.response ? error.response.data : 'No response data');
        }
    };

    useEffect(() => {
        fetchUnreadCount();
    }, []);

    const calculateUnreadCount = (receivedMessages) => {
        const count = receivedMessages.filter(message => !message.is_read).length;
        setUnreadCount(count);
    };

    const handleLogout = () => {
        AxiosAuthInstance.post('logout').then((r) => {
            localStorage.removeItem('id')
            localStorage.removeItem('email')
            localStorage.removeItem('username')
            localStorage.removeItem('name')
            localStorage.removeItem('role')
            localStorage.removeItem('user_status')
            localStorage.removeItem('token')
            window.location.href = '/';
        });
    };

    return (
        <>
            <div className="h-[100px] bg-[#B4C6D9] content-center sticky top-0 z-10">
                <nav className="w-[1400px] flex flex-wrap items-center justify-between mx-auto">
                    <Link
                        to="/dashboard"
                        className="svgx flex justify-item-start h-[35] w-[35] p-2 rounded bg-white hover:bg-[#657E98] hover:transition hover:duration-300"
                    >
                        <svg
                            id="Group_63"
                            data-name="Group 63"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="26.19"
                            height="23.833"
                            viewBox="0 0 26.19 23.833"
                        >
                            <defs>
                                <clipPath id="clip-path">
                                    <rect
                                        id="Rectangle_59"
                                        data-name="Rectangle 59"
                                        width="26.19"
                                        height="23.833"
                                        transform="translate(0 0)"
                                        fill="#657e98"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="Group_61"
                                data-name="Group 61"
                                transform="translate(0 0)"
                                clipPath="url(#clip-path)"
                            >
                                <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M25.926,12.63,21,7.657V1.577a.327.327,0,0,0-.327-.327H18.309a.327.327,0,0,0-.327.327V4.608L13.59.177a.613.613,0,0,0-.869,0L.267,12.63A.912.912,0,0,0,1.2,14.141h0l2.6-.885v9.822a.75.75,0,0,0,.75.75h4.9a.75.75,0,0,0,.75-.75V15.621h5.927v7.457a.75.75,0,0,0,.75.75h4.9a.75.75,0,0,0,.75-.75V13.292l2.43.829a.912.912,0,0,0,.981-1.491"
                                    transform="translate(0 0.005)"
                                    fill="#657e98"
                                />
                            </g>
                        </svg>
                    </Link>
                    <Link
                        to="/"
                        className="flex justify-item-center items-center ms-40 overflow-hidden"
                    >
                        <span
                            className="self-center text-[32px] inter-light whitespace-nowrap dark:text-black ps-28"
                        >
                            Surgery South, P.C.
                        </span>
                    </Link>
                    <div className="flex justify-item-end items-center overflow-hidden">
                        <Link to="/messages">
                            <button
                                type="button"
                                className="border-2 px-2 py-0 rounded bg-[#D8ADAD] mx-2 inter-medium"
                            >
                                {unreadCount}
                            </button>
                        </Link>
                        <Link to="/messages">
                            <button
                                type="button"
                                className="border-none px-4 py-2 rounded bg-white mx-2 inter-medium hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                            >
                                Messages
                            </button>
                        </Link>

                        {userRole == 1 && (
                        <Link to="/administration">
                            <button
                                type="button"
                                className="border-none px-4 py-2 rounded bg-white mx-2 inter-medium hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                            >
                                Administration
                            </button>
                        </Link>
                        )}

                        <button onClick={handleLogout} class="bg-white h-[37px] w-[37px] flex items-center justify-center mt-0 rounded hover:bg-[#D8ADAD] group relative">
                            <img src="assets/images/logout icon.svg" alt="Original Image" class="block" />
                            <img src="assets/images/logout state 2.svg" alt="Hover Image" class="absolute hover-img" />
                        </button>
                    </div>
                </nav>
            </div>
            <div className="body-content bg-[#ECECEC] mt-[35px] mb-[35px]">
                <div className="w-[1165px] mx-auto py-0">
                    <Link to="hospital-round.html">
                        <div
                            className="hospital-round mb-[18px] bg-[#B4C6D9] px-5 h-[55px] flex items-center justify-center rounded-xl text-center hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                        >
                            <p className="inter-medium text-[20px]">Hospital Round</p>
                        </div>
                    </Link>
                    <div
                        className="hp-round-items flex bg-white rounded-xl h-[72px] px-3 justify-between items-center overflow-hidden"
                    >
                        {Array(7).fill(null).map((_, idx) => (
                            <div
                                key={idx}
                                className="item xl:w-[150px] lg:w-[80px] bg-[#B4C6D9] h-[50px] flex justify-center items-center mx-2 rounded-[5px] inter-medium text-[18px] text-center"
                            >
                                19/90
                            </div>
                        ))}
                    </div>
                    <Link to="operative.html">
                        <div
                            className="operative-log mt-10 mb-5 bg-[#B4C6D9] px-5 mt-[40px] mb-[18px] h-[55px] flex justify-center items-center rounded-xl text-center hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                        >
                            <p className="inter-medium text-[20px]">Operative Log</p>
                        </div>
                    </Link>
                    <div
                        className="op-log-items flex bg-white rounded-xl h-[72px] px-3 items-center justify-between overflow-hidden"
                    >
                        {Array(7).fill(null).map((_, idx) => (
                            <div
                                key={idx}
                                className="item xl:w-[150px] lg:w-[80px] bg-[#B4C6D9] h-[50px] flex justify-center items-center mx-2 rounded-[5px] inter-medium text-[18px] text-center"
                            >
                                19/90
                            </div>
                        ))}
                    </div>
                    <Link to="schedule.html">
                        <div
                            className="surgery-schedule mt-[40px] mb-[18px] bg-[#B4C6D9] px-5 h-[55px] flex justify-center items-center rounded-xl text-center hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                        >
                            <p className="inter-medium text-[20px]">Daily Surgery Schedule</p>
                        </div>
                    </Link>
                    <div className="surgery-item relative">
                        <div
                            className="flex bg-white rounded-xl h-[55px] items-center px-20 justify-between overflow-hidden"
                        >
                            {Array(15).fill(null).map((_, idx) => (
                                <div
                                    key={idx}
                                    className="item w-[57px] text-[14px] border-2 border-black h-[30px] justify-center items-center mx-1 flex rounded-md inter-medium content-center"
                                >
                                    12/19
                                </div>
                            ))}
                        </div>
                        <div
                            className="prev absolute start-5 top-3 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i className="fa-solid fa-xl fa-angle-left"></i>
                        </div>
                        <div
                            className="next absolute end-5 top-3 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i className="fa-solid fa-xl fa-angle-right"></i>
                        </div>
                    </div>
                    <div className="surgery-month relative pt-2">
                        <div
                            className="flex bg-white rounded-xl h-[55px] items-center px-20 justify-between overflow-hidden slider-container"
                        >
                            {['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'].map((month, idx) => (
                                <div
                                    key={idx}
                                    className={`item w-[75px] text-[14px] border-2 border-black h-[30px] mx-1 flex justify-center items-center rounded-md inter-medium content-center ${idx === 8 ? 'active' : ''}`}
                                >
                                    {month}
                                </div>
                            ))}
                        </div>
                        <div
                            className="prev absolute start-5 top-5 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i className="fa-solid fa-xl fa-angle-left"></i>
                        </div>
                        <div
                            className="next absolute end-5 top-5 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i className="fa-solid fa-xl fa-angle-right"></i>
                        </div>
                    </div>

                    <Link to="monthly-call.html">
                        <div
                            class="Call-Calender mt-[40px] mb-[18px] bg-[#B4C6D9] px-5 h-[55px] flex items-center justify-center rounded-xl text-center hover:bg-[#657E98] hover:text-white hover:transition hover:duration-300"
                        >
                            <p class="inter-medium text-[20px]">Monthly Call Calender 2024</p>
                        </div>
                    </Link>
                    <div class="calender-month relative">

                        <div
                            class="flex bg-white rounded-xl h-[55px] items-center px-20 justify-between overflow-hidden"
                        >
                            {['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sept.',
                                'Oct.', 'Nov.', 'Dec.'].map((month, idx) => (<div key={idx}
                                    className={`item w-[75px] text-[14px] border-2 border-black h-[30px] mx-1
                                    flex justify-center items-center rounded-md inter-medium content-center
                                    ${idx === 8 ? 'active' : ''}`} > {month}
                                </div>
                                ))}
                        </div>

                        <div
                            class="prev absolute start-5 top-3 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i class="fa-solid fa-xl fa-angle-left"></i>
                        </div>
                        <div
                            class="next absolute end-5 top-3 border-none bg-[#657E98] border-2 h-[30px] w-[30px] flex justify-center items-center rounded text-white"
                        >
                            <i class="fa-solid fa-xl fa-angle-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
