import React from 'react'
import Breadcrumb from '../Partials/Breadcrumb'

const Error401 = () => {
    return (
        <>
            <Breadcrumb title={'Error 401'} />
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h1 className='text-danger'> You are not authorized to view this page!  </h1>
                </div>
            </div>
        </>
    )
}

export default Error401