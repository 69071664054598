import React from 'react'
import Breadcrumb from '../Partials/Breadcrumb'

const Error404 = () => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <h1 className='text-danger'> Page not found!  </h1>
                </div>
            </div>
        </>
    )
}

export default Error404